import { MenuItem, Select, TextField, Typography } from "@material-ui/core"
import React, { useState } from "react"
import "./Calculator.css"

export default function Calculator() {
    const [totalSize, setTotalSize] = useState("")
    const [solutionSize, setSolutionSize] = useState("")
    const [dilutionRate, setDilutionRate] = useState("")
    const [stennerTube, setStennerTube] = useState("5-tube")
    const [mode, setMode] = useState("dilution-chlorine")

    let dilutionString = null
    if (totalSize.length > 0 && !isNaN(totalSize) && solutionSize.length > 0 && !isNaN(solutionSize) && dilutionRate.length > 0 && !isNaN(dilutionRate)) {
        const totalGallonsToAdd = totalSize - solutionSize
        const dilutionFraction = parseFloat(dilutionRate) / (parseFloat(dilutionRate) + 1)
        const chlorineGallonsToAdd = totalGallonsToAdd - (totalGallonsToAdd * dilutionFraction)
        const chlorineGallonsToAddRounded = chlorineGallonsToAdd.toFixed(1)
        if (mode === "dilution-chlorine") {
            dilutionString = `Add ${chlorineGallonsToAddRounded} gal (${(chlorineGallonsToAddRounded / 2.5).toFixed(1)} jugs) of chlorine and ${(totalGallonsToAdd - chlorineGallonsToAddRounded).toFixed(1)} gal of water`
        }
        if (mode === "dilution-acid") {
            dilutionString = `Add ${chlorineGallonsToAddRounded} gal of acid and ${(totalGallonsToAdd - chlorineGallonsToAddRounded).toFixed(1)} gal of water`
        }
    }

    let stennerString = null
    if (totalSize.length > 0 && !isNaN(totalSize)) {
        let galPerDayOnMaxSetting = 0
        if (stennerTube === "5-tube") galPerDayOnMaxSetting = 50
        if (stennerTube === "2-tube") galPerDayOnMaxSetting = 10
        const days = 3
        const gallonsAfterDays = 5
        const maxSetting = 10
        let tankSize = parseFloat(totalSize)
        let stennerSetting = (tankSize - gallonsAfterDays) / days / galPerDayOnMaxSetting * maxSetting
        if (stennerSetting > maxSetting) stennerString = "Cannot use this tube size with this tank size - try a different tube size."
        else if (stennerSetting <= 0) stennerString = "Tank size too small."
        else stennerString = `Set the stenner pump to ${stennerSetting.toFixed(1)}`
    }

    return (
        <div>
            <Typography variant="h4">A-Quality Dilutions</Typography>
            <div className="input-section">
                <Select
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                    variant="outlined"
                >
                    <MenuItem value={"dilution-chlorine"}>Chlorine Dilution</MenuItem>
                    <MenuItem value={"dilution-acid"}>Acid Dilution</MenuItem>
                    <MenuItem value={"stenner"}>Stenner Setting</MenuItem>
                </Select>
            </div>
            {mode === "stenner" ? (
                <>
                    <div className="input-section">
                        <Select
                            value={stennerTube}
                            onChange={(e) => setStennerTube(e.target.value)}
                            variant="outlined"
                            className="fullwidth"
                        >
                            <MenuItem value={"5-tube"}>#5 Tube</MenuItem>
                            <MenuItem value={"2-tube"}>#2 Tube</MenuItem>
                        </Select>
                    </div>
                    <div className="input-section">
                        <TextField
                            label="Tank Size (gal)"
                            variant="outlined"
                            type="number"
                            value={totalSize}
                            onChange={(e) => setTotalSize(e.target.value)}
                            className="fullwidth"
                        />
                    </div>
                    {stennerString != null && (
                        <div>
                            <Typography variant="subtitle1">{stennerString}</Typography>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="input-section">
                        <TextField
                            label="Tank Size (gal)"
                            variant="outlined"
                            type="number"
                            value={totalSize}
                            onChange={(e) => setTotalSize(e.target.value)}
                            className="fullwidth"
                        />
                    </div>
                    <div className="input-section">
                        <TextField
                            label="Solution in tank (gal)"
                            variant="outlined"
                            type="number"
                            value={solutionSize}
                            onChange={(e) => setSolutionSize(e.target.value)}
                            className="fullwidth"
                        />
                    </div>
                    <div className="input-section">
                        <TextField
                            label="Dilution Rate - Water ratio to 1 (X:1)"
                            type="number"
                            variant="outlined"
                            value={dilutionRate}
                            onChange={(e) => setDilutionRate(e.target.value)}
                            className="fullwidth"
                        />
                    </div>
                    {dilutionString != null && (
                        <div>
                            <Typography variant="subtitle1">{dilutionString}</Typography>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
    palette: {
        primary: {
            light: '#63a4ff',
            main: '#1976d2',
            dark: '#004ba0',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#62eaff',
            main: '#00b7d3',
            dark: '#0087a2',
            contrastText: '#000000',
        },
    },
})
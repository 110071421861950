import { AppBar, Container, ThemeProvider, Toolbar, Typography } from '@material-ui/core';
import Calculator from './Calculator';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">
            A-Quality Dilution
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{"marginTop": "30px"}}>
        <Calculator />
      </Container>
    </ThemeProvider>
  );
}

export default App;
